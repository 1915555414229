<template>
  <cz-collection :class="customClass" :id="elementId" :find-route-name="findRouteName">

    <template v-if="!computedQuery">
      <div class="race-body">
        <div class="race-body-item" v-for="(item,index) in tableData" :key="index" @click="itemClicked(item)">
          <div style="display: flex;justify-content:space-between;margin-bottom:10px;">
            <p class="race-text">{{ item.title }}</p>
            <p class="race-timer">{{ computedDateTime(item.time) }}</p>
          </div>
          <div style="display: flex; justify-content: space-around;">
            <p class="subtitle">{{ item.summary }}</p>
            <p></p>
          </div>
        </div>
      </div>
      <div class="video-home" style="display: flex;flex-wrap: wrap;">
        <div class="fenYe">
          <el-pagination
              prev-text="上一页"
              next-text="下一页"
              class="fenYeS"
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="prev, pager, next, total"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </template>

    <template v-if="computedQuery">
      <cz-document-component :form-object="currentDocument"></cz-document-component>
    </template>
  </cz-collection>
</template>

<script type="text/ecmascript-6">

import {toMatrix}      from '@/utils'
import {isEmpty}       from 'lodash'
import DataConfigMixin from '@/mixins/data-config-mixin'
import moment          from 'moment'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')

export default {
  // extends   : {},
  mixins    : [DataConfigMixin],
  components: {},
  name      : 'DocumentViewList',
  props     : {
    className: { type: String, default: '' },
  },
  data() {
    return {
      twoDimensionalArray: [],
      tableData          : [],
      total              : 0,
      pageSize           : 6,
      currentPage        : 1,
      ///
      findRouteName: '',
      // dataList     : [],
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    computedDateTime() {
      return (time) => {
        if (Object.prototype.toString.call(time) === '[object String]') {
          // return moment(time, 'YYYY.MM.DD').format('YYYY.MM.DD')
          return time
        }
        else if (Object.prototype.toString.call(time) === '[object Number]') {
          return moment(time).format('YYYY.MM.DD')
        }
        else {
          return moment(time).format('YYYY.MM.DD')
        }
      }
    },
    elementId() {return `DocumentViewList_${this._uid}`},
    customClass() {return ['DocumentViewList', this.className]},
    dataList() {
      let valueByPath = this.getValueByPath(this.dataConfig, this.$route.meta.dataPath)
      console.log('【document-view-list:93】',`valueByPath = `,valueByPath);
      console.log('【document-view-list:93】',`valueByPath = `,valueByPath);
      console.log('【document-view-list:93】',`valueByPath = `,valueByPath);
      return valueByPath
    },
    computedQuery() {
      if (isEmpty(this.$route.query)) {
        //
      }
      else {
        return this.$route.query.d
      }
    },
    currentDocument() {
      if (this.computedQuery) {
        let find = this.dataList.find(item => {
          return item.id == this.computedQuery
        })
        if (isEmpty(find)) {
          return null
        }
        else {
          return find
        }
      }
      else {
        return null
      }
    },
  },
  methods : {
    getValueByPath(object, prop) {
      prop        = prop || ''
      const paths = prop.split('.')
      let current = object
      let result  = null
      for (let i = 0, j = paths.length; i < j; i++) {
        const path = paths[i]
        if (!current) {
          break
        }

        if (i === j - 1) {
          result = current[path]
          break
        }
        current = current[path]
      }
      return result
    },
    getPropByPath(obj, path, strict) {
      let tempObj = obj
      path        = path.replace(/\[(\w+)\]/g, '.$1')
      path        = path.replace(/^\./, '')

      let keyArr = path.split('.')
      let i      = 0
      for (let len = keyArr.length; i < len - 1; ++i) {
        if (!tempObj && !strict) {
          break
        }
        let key = keyArr[i]
        if (key in tempObj) {
          tempObj = tempObj[key]
        }
        else {
          if (strict) {
            throw new Error('please transfer a valid prop path to form item!')
          }
          break
        }
      }
      return {
        o: tempObj,
        k: keyArr[i],
        v: tempObj ? tempObj[keyArr[i]] : null,
      }
    },
    itemClicked(item) {
      // this.$router.push({ name: 'DocumentViewList', query: { d: item.id } })
      this.$router.push({ name: this.$route.name, query: { d: item.id } })
    },
    // 多少条一页 改变事件
    handleSizeChange(val) {
      this.pageSize = val
      this.getTableData()
    },
    // 当前页码 改变事件
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTableData()
    },
    getTableData() {
      let dataList             = this.dataList
      this.twoDimensionalArray = toMatrix(dataList, this.pageSize)
      this.tableData           = this.twoDimensionalArray[this.currentPage - 1]
      this.total               = dataList.length
    },
  },
  created() {
    // document.documentElement.scrollTop = 0
    this.getTableData()

    this.$nextTick(() => {
      this.findRouteName = this.$route.meta.parent
    })
  },
  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
// race-news
// @import "DocumentViewList.scss"
.DocumentViewList {
  background : rgba(242, 242, 242, 1);

  .race-body {
    width           : 1200px;
    //background: rgba(242,242,242,1);
    display         : flex;
    flex-wrap       : wrap;
    justify-content : space-between;
    margin          : auto;
  }

  ::v-deep .el-menu {
    margin          : 0 auto;
    width           : 1200px;
    display         : flex;
    padding         : 40px 0 20px;
    background      : rgba(242, 242, 242, 1);
    justify-content : flex-start;

    .el-menu-item {
      width         : 120px;
      height        : 40px;
      background    : #FFFFFF;
      border-radius : 2px;
      margin-right  : 30px;
      line-height   : 40px;
      text-align    : center;
    }

    .is-active {
      background : #CF000D;
      color      : #fff;
    }
  }

  .race-body-item {
    width         : 565px;
    height        : 148px;
    padding       : 20px 30px;
    margin        : 0 0 30px;
    background    : #ffffff;
    border-radius : 6px;
    cursor        : pointer;
    box-sizing    : border-box;
  }

  .race-text {
    width              : 420px;
    font-size          : 20px;
    font-weight        : 400;
    line-height        : 28px;
    color              : #181921;
    overflow           : hidden;
    text-overflow      : ellipsis;
    display            : -webkit-box;
    -webkit-line-clamp : 2;
    word-break         : break-all; // 注意这个文字多行很重要
    -webkit-box-orient : vertical;
    margin             : 0;
  }

  .race-timer {
    font-size   : 12px;
    font-weight : 400;
    color       : #65697E;
    line-height : 17px;
    margin      : 10px 0 0 0;
  }

  .subtitle {
    width              : 525px;
    margin             : 0;
    font-size          : 14px;
    font-weight        : 400;
    color              : #65697E;
    line-height        : 20px;
    overflow           : hidden;
    text-overflow      : ellipsis;
    display            : -webkit-box;
    -webkit-line-clamp : 2;
    word-break         : break-all; // 注意这个文字多行很重要
    -webkit-box-orient : vertical;
  }

  .foot-pagination {
    margin : 70px auto 100px;
  }

  .fenYe {
    background  : #F5F6FA;
    margin      : 70px auto;
    display     : flex;
    align-items : center;
  }

  .fenYe > .fenYeS {
    margin : auto;
  }

  .el-pagination {
    line-hegiht : 40px;
  }

  .fenYeS ::v-deep .btn-prev, .fenYeS ::v-deep .btn-next, .fenYeS ::v-deep .el-pager, .fenYeS ::v-deep .el-pagination__total {
    height : 40px;
  }

  .fenYeS ::v-deep .el-pager .number {
    width         : 40px;
    height        : 40px;
    text-align    : center;
    line-height   : 40px;
    background    : #FFFFFF;
    border-radius : 2px;
    border        : 1px solid #EAEAEA;
  }

  .fenYeS ::v-deep .btn-prev span, .fenYeS ::v-deep .btn-next span {
    width         : 72px;
    height        : 40px;
    line-height   : 40px;
    background    : #FFFFFF;
    border-radius : 2px;
    font-size     : 14px;
    font-family   : PingFangSC-Regular, PingFang SC;
    font-weight   : 400;
    color         : #181921;
  }

  .fenYeS ::v-deep .active {
    background : #CF000D !important;
  }

  .fenYeS ::v-deep .el-pagination__total {
    line-height : 40px;
    margin-left : 10px;
    font-size   : 14px;
    font-family : PingFangSC-Regular, PingFang SC;
    font-weight : 400;
    color       : #222222;
  }
}
</style>
